import React from 'react';

function HeroVideo(props) {
        return(
            <div className="iberica-div-video">
                <video className="video-iberica" autoPlay loop muted poster={props.props.poster.file.url} width="100vw">
                        <source src={props.props.video.file.url}
                            type={props.props.video.file.contentType}/>
                </video>
            </div>
        )
} 

export default HeroVideo